<template>
  <div class="animated fadeIn">
    <b-card class="card-border mt-4">
      <b-card-title><i class="fa fa-users"></i> User</b-card-title>
      <b-card-sub-title>Manages the user registration</b-card-sub-title>
      <div fluid class="px-2 mt-4">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          color="#20A8D8"
          :is-full-page="false"
        />

        <!-- Filter  -->
        <b-row class="mt-2">
          <b-col sm="12" md="3" lg="3">
            <b-button
              v-b-popover.hover.right="'Toggle to show/hide filter options'"
              v-b-toggle.collapse-1
              class="filter"
            >
              FILTER OPTIONS
            </b-button>
          </b-col>
          <b-col sm="12">
            <!-- Collapsible Filter Options -->
            <b-collapse id="collapse-1" class="mt-2">
              <b-card>
                <b-row no-gutters>
                  <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Company">
                      <v-select
                        class="style-chooser"
                        label="text"
                        placeholder=" - Please select - "
                        :options="companyOptions"
                        :reduce="(company) => company.value"
                        v-model="filterBy.company"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No results found for
                            <em>
                              <strong>{{ search }}</strong>
                            </em>
                          </template>
                          <em :style="{ opacity: 0.5 }" v-else>
                            Start typing to search for a company
                          </em>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Status">
                      <v-select
                        class="style-chooser"
                        label="text"
                        placeholder=" - Please select - "
                        :options="statusOptions"
                        :reduce="(isActive) => isActive.value"
                        v-model="filterBy.isActive"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No results found for
                            <em>
                              <strong>{{ search }}</strong>
                            </em>
                          </template>
                          <em :style="{ opacity: 0.5 }" v-else>
                            Start typing to search for status
                          </em>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Type">
                      <v-select
                        class="style-chooser"
                        label="text"
                        placeholder=" - Please select - "
                        :options="userTypeOptions"
                        :reduce="(userType) => userType"
                        v-model="filterBy.userType"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No results found for
                            <em>
                              <strong>{{ search }}</strong>
                            </em>
                          </template>
                          <em :style="{ opacity: 0.5 }" v-else>
                            Start typing to search for a company
                          </em>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="5" sm="12" class="mr-4">
                    <b-form-group label="Email">
                      <b-form-input
                        name="Email"
                        type="search"
                        v-model="filterBy.emailAddress"
                        placeholder
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="5" sm="12" class="mr-4">
                    <b-form-group label="Employee No.">
                      <b-form-input
                        name="Employee No"
                        type="search"
                        class="numFont"
                        v-model="filterBy.employeeNo"
                        placeholder
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row no-gutters>
                  <b-col sm="12">
                    <b-button class="mr-1" variant="success" @click="onFilterRequest">
                      Search
                    </b-button>
                    <b-button class="mr-1" variant="primary" @click="resetFilters">
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>

        <!-- Select Actions and Items Per Page Options -->
        <b-row>
          <b-col sm="6" md="3" class="mt-4 mb-2">
            <b-dropdown text=" Select Actions " variant="dark" slot="append">
              <b-dropdown-item v-b-modal.add-user v-show="!isViewer">
                Add User
              </b-dropdown-item>
              <b-dropdown-item>
                <json-excel
                  :data="exportData"
                  :fields="exportFields"
                  type="xls"
                  :name="fileName + '.xls'"
                >
                  Export Users in Excel
                </json-excel>
              </b-dropdown-item>
              <b-dropdown-item>
                <json-excel
                  :data="exportData"
                  :fields="exportFields"
                  type="csv"
                  :name="fileName + '.csv'"
                >
                  Export Users to CSV
                </json-excel>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
            <b-input-group prepend="Show" append="/ Page">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-input-group>
          </b-col>
        </b-row>

        <b-table
          ref="usersTable"
          show-empty
          striped
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
        >
          <template v-slot:cell(actions)="row">
            <span class="text-nowrap">
              <b-button
                size="sm"
                v-b-tooltip.hover.top="'Show/Hide Other Details'"
                variant="dark"
                @click.stop="row.toggleDetails"
                class="mr-1"
              >
                <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
                <i class="fa fa-eye" v-else></i>
              </b-button>
              <b-button
                size="sm"
                v-b-modal.edit-user
                v-b-tooltip.hover.top="'Edit Details'"
                variant="warning"
                @click.stop="updateSelUser(row.item)"
                class="mr-1"
                v-show="!isViewer"
              >
                <i class="fa fa-pencil"></i>
              </b-button>
              <b-button
                :id="row.item.firstName"
                size="sm"
                v-b-modal.assign-company-access
                v-b-tooltip.hover.top="'Assign Company Access'"
                variant="success"
                @click.stop="updateSelUser(row.item)"
                class="mr-1"
                v-show="!isViewer && isParentCompany()"
              >
                <b-icon icon="person-plus-fill"></b-icon>
              </b-button>
            </span>
          </template>

          <template v-slot:cell(status)="row">
            <a
              @click="updateSelUser(row.item)"
              v-if="row.item.id != loggedUser.id && !isViewer"
            >
              <b-form-checkbox
                name="status"
                v-b-modal.activate-user
                v-model="row.item.isActive"
                switch
                size="sm"
              >
                {{ row.item.isActive === "true" ? "Active" : "Inactive" }}
              </b-form-checkbox>
            </a>
            <span v-else>
              {{ row.item.isActive === "true" ? "Active" : "Inactive" }}
            </span>
          </template>

          <template slot="row-details" slot-scope="row">
            <UserDetailsView :row="row" />
          </template>
        </b-table>

        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="total-display">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination
              align="right"
              :total-rows="items.length"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modals here -->
    <AddUser
      :companyOptions="companyOptions"
      :allCompaniesObj="allCompaniesObj"
      :allUsersObj="allUsersObj"
    />
    <EditUser
      :companyOptions="companyOptions"
      :allCompaniesObj="allCompaniesObj"
      :allUsersObj="allUsersObj"
    />
    <ActivateUser :allCompaniesObj="allCompaniesObj" :allUsersObj="allUsersObj" />
    <AssignCompanyAccess :allCompaniesObj="allCompaniesObj" />
  </div>
</template>

<script>
// Component
import AddUser from "./user/AddUser";
import EditUser from "./user/EditUser";
import ActivateUser from "./user/ActivateUser";
import AssignCompanyAccess from "./user/AssignCompanyAccess";
import UserDetailsView from "./user/UserDetailsView";

// Util
import { DateUtil } from "@/utils/dateutil";
import { DropDownItemsUtil } from "@/utils/dropDownItemsUtil";

// API
import userApi from "@/api/userApi";

// Others
import config from "@/config/env-constants";
import EventBus from "@/shared/event-bus";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import JsonExcel from "vue-json-excel";
import _ from "lodash";

export default {
  name: "user",
  components: {
    AddUser,
    EditUser,
    ActivateUser,
    AssignCompanyAccess,
    UserDetailsView,
    Loading,
    JsonExcel,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "firstName",
          sortable: true,
        },
        {
          key: "lastName",
          sortable: true,
        },
        {
          key: "company",
          sortable: true,
        },
        {
          key: "type",
          sortable: true,
        },
        "status",
        {
          key: "actions",
          thClass: "text-center",
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 15, 25, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,

      companyOptions: [],
      userTypeOptions: [...config.userTypes, ...config.userTypesDisposalAndRepair],
      statusOptions: config.statusOptionsWithDefault,

      allCompaniesObj: {},
      allUsersObj: {},
      selUser: {},

      defaultFilterBy: {
        company: { ...config.companyDefaultValue },
        userType: { ...config.dropdownDefaultValue },
        isActive: "true",
        emailAddress: null,
        employeeNo: null,
      },
      filterBy: {
        company: { ...config.companyDefaultValue },
        userType: { ...config.dropdownDefaultValue },
        isActive: "true",
        emailAddress: null,
        employeeNo: null,
      },
      prevFilter: {},

      isSuperAdmin: this.$store.getters.isSuperAdmin,
      isViewer: this.$store.getters.isViewer,
      loggedUser: this.$store.getters.loggedUser,
      loggedUserCompany: this.$store.getters.loggedUserCompany,
      // Check for loader
      isLoading: false,
    };
  },
  watch: {
    "filterBy.employeeNo": function () {
      if (_.isEmpty(this.filterBy.employeeNo)) {
        this.filterBy.employeeNo = null;
      }
    },
    "filterBy.emailAddress": function () {
      if (_.isEmpty(this.filterBy.emailAddress)) {
        this.filterBy.emailAddress = null;
      }
    },
  },
  computed: {
    /**
     * Returns the set of data to be included in the export. For now this just
     * returns the data as is.
     *
     * @returns {Array} the set of data to be included in the export.
     */
    exportData() {
      return this.items;
    },

    /**
     * Derives the field information based from the data table configuration.
     *
     * @returns {object} the fields to be included in the export.
     */
    exportFields() {
      return {
        "First Name": "firstName",
        "Middle Name": "middleName",
        "Last Name": "lastName",
        Company: "company",
        Position: "position",
        "Employee No": "Employee No",
        Type: "type",
        "Contact Number": "contactNo",
        Email: "emailAddress",
        Status: "status",
        "Date Created": "Date Created",
        "Date Updated": "Date Updated",
        "Created By": "createdBy",
        "Updated By": "updatedBy",
      };
    },

    fileName() {
      let currTimeStamp = DateUtil.getCurrentTimestamp();
      return "User-" + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
    },
  },
  mounted() {
    setTimeout(() => {
      try {
        // Filter Access
        if (
          this.$store.getters.isScanner ||
          this.$store.getters.isAccounting ||
          this.$store.getters.isApprover ||
          this.$store.getters.isMaintenance
        ) {
          this.$router.push("/dashboard");
          this.$toaster.warning("You are not allowed to access this page.");
        }

        // show loading indicator
        this.isLoading = true;

        this.allCompaniesObj = { ...this.$store.getters.companies };
        this.companyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

        this.allUsersObj = { ...this.$store.getters.users };

        this.filterTableContent();
      } catch (_error) {
        this.$toaster.error("Error loading data. Please reload the page again.");
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    }, config.timeout);

    // Event Listeners
    EventBus.$on("onCloseSaveUser", (userObj) => {
      this.updateTable(userObj);
    });
  },
  methods: {
    updateTable(userObj) {
      if (!_.isEmpty(userObj)) {
        this.allUsersObj[userObj.id] = userObj;
        this.$store.dispatch("updateAllUsers", this.allUsersObj);

        this.filterTableContent();
      }
    },
    filterTableContent() {
      let filteredObjs = { ...this.allUsersObj };

      _.forEach(filteredObjs, (user, userId) => {
        let company = this.filterBy.company ? this.filterBy.company : {};
        let companyId = company.id ? company.id : "";
        if (companyId.length > 0 && companyId !== user.companyId) {
          delete filteredObjs[userId];
        }

        if (this.filterBy.isActive !== null && this.filterBy.isActive !== user.isActive) {
          delete filteredObjs[userId];
        }

        if (
          this.filterBy.userType.value !== null &&
          this.filterBy.userType.value !== user.type
        ) {
          delete filteredObjs[userId];
        }

        if (
          this.filterBy.emailAddress !== null &&
          this.filterBy.emailAddress !== user.emailAddress
        ) {
          delete filteredObjs[userId];
        }

        if (
          this.filterBy.employeeNo !== null &&
          this.filterBy.employeeNo !== user.employeeNo
        ) {
          delete filteredObjs[userId];
        }

        user.employeeNoStr = "";
        if (user.employeeNo && user.employeeNo.length > 0) {
          user.employeeNoStr = "'" + user.employeeNo;
        }
      });

      this.processUsers(filteredObjs);
    },
    filterByCompany(filteredObj, user, userId) {
      let company = this.filterBy.company ? this.filterBy.company : {};
      let companyIdFilter = company.id ? company.id : "";
      if (companyIdFilter.length > 0 && companyIdFilter !== user.companyId) {
        delete filteredObj[userId];
      }
    },
    processUsers(filteredObj) {
      this.items = Object.values(filteredObj);

      // Parse Column Values
      _.forEach(this.items, (item) => {
        item["Employee No"] = "";
        if (item.employeeNo && item.employeeNo.length > 0) {
          item["Employee No"] = "'" + item.employeeNo;
        }

        item["status"] = item.isActive === "true" ? "Active" : "Inactive";
        item["Date Created"] = this.getFormattedDateWithTime(item.dateCreated);
        item["Date Updated"] = this.getFormattedDateWithTime(item.dateUpdated);

        // remove show details
        delete item._showDetails;
      });
      this.items = _.sortBy(this.items, ["firstName", "lastName"]);
      this.totalRows = this.items.length;

      // refresh table
      if (this.$refs.usersTable) {
        this.$refs.usersTable.refresh();
      }
    },

    async onFilterRequest() {
      if (!_.isEqual(this.filterBy, this.prevFilter)) {
        await this.retrieveData();
        this.prevFilter = { ...this.filterBy };
      }
    },
    resetFilters() {
      if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
        // reset to default
        this.filterBy = { ...this.defaultFilterBy };
        this.prevFilter = { ...this.filterBy };

        // reset validation
        this.$validator.reset();
        this.errors.clear();

        this.allUsersObj = { ...this.$store.getters.users };
        this.filterTableContent();
      }
    },
    async retrieveData() {
      try {
        // show loading indicator
        this.isLoading = true;

        let filter = { ...this.filterBy };
        filter.companyId = this.loggedUserCompany.id;

        if (this.isSuperAdmin) {
          const { data } = await userApi.getUsers(
            filter,
            config.view.ADMIN,
            this.loggedUser.id
          );
          this.allUsersObj = _.assign(this.allUsersObj, data.users);
        } else {
          const { data } = await userApi.getUsers(
            filter,
            config.view.COMPANY,
            this.loggedUser.id
          );
          this.allUsersObj = _.assign(this.allUsersObj, data.users);
        }

        this.filterTableContent();
      } catch (_error) {
        this.$toaster.error("Error loading data. Please reload the page again.");
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    },

    selectCompany(company) {
      this.filterBy.company = company;
      this.$store.commit("SET_CURR_COMPANY", company);
      this.retrieveData();
    },
    updateSelUser(user) {
      this.selUser = user;
      this.$store.commit("SET_CURR_USER", user);
      EventBus.$emit("onUpdateSelUser", user);
    },
    isParentCompany() {
      let userCompany = this.allCompaniesObj[this.loggedUser.companyId];
      return !userCompany.hasParentCompany;
    },
    getFormattedDateWithTime(date) {
      return DateUtil.getFormattedDateWithTime(date);
    },
  },
  beforeDestroy() {
    EventBus.$off("onCloseSaveUser");
  },
};
</script>
